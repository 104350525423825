.m-0 {
  margin: 0 !important; }

.ml-0, .mx-0 {
  margin-left: 0 !important; }

.mr-0, .mx-0 {
  margin-right: 0 !important; }

.mt-0, .my-0 {
  margin-top: 0 !important; }

.mb-0, .my-0 {
  margin-bottom: 0 !important; }

.p-0 {
  padding: 0; }

.pl-0, .px-0 {
  padding-left: 0; }

.pr-0, .px-0 {
  padding-right: 0; }

.pt-0, .py-0 {
  padding-top: 0; }

.pb-0, .py-0 {
  padding-bottom: 0; }

.m-1 {
  margin: 0.25rem !important; }

.ml-1, .mx-1 {
  margin-left: 0.25rem !important; }

.mr-1, .mx-1 {
  margin-right: 0.25rem !important; }

.mt-1, .my-1 {
  margin-top: 0.25rem !important; }

.mb-1, .my-1 {
  margin-bottom: 0.25rem !important; }

.p-1 {
  padding: 0.25rem; }

.pl-1, .px-1 {
  padding-left: 0.25rem; }

.pr-1, .px-1 {
  padding-right: 0.25rem; }

.pt-1, .py-1 {
  padding-top: 0.25rem; }

.pb-1, .py-1 {
  padding-bottom: 0.25rem; }

.m-2 {
  margin: 0.5rem !important; }

.ml-2, .mx-2 {
  margin-left: 0.5rem !important; }

.mr-2, .mx-2 {
  margin-right: 0.5rem !important; }

.mt-2, .my-2 {
  margin-top: 0.5rem !important; }

.mb-2, .my-2 {
  margin-bottom: 0.5rem !important; }

.p-2 {
  padding: 0.5rem; }

.pl-2, .px-2 {
  padding-left: 0.5rem; }

.pr-2, .px-2 {
  padding-right: 0.5rem; }

.pt-2, .py-2 {
  padding-top: 0.5rem; }

.pb-2, .py-2 {
  padding-bottom: 0.5rem; }

.m-3 {
  margin: 1rem !important; }

.ml-3, .mx-3 {
  margin-left: 1rem !important; }

.mr-3, .mx-3 {
  margin-right: 1rem !important; }

.mt-3, .my-3 {
  margin-top: 1rem !important; }

.mb-3, .my-3 {
  margin-bottom: 1rem !important; }

.p-3 {
  padding: 1rem; }

.pl-3, .px-3 {
  padding-left: 1rem; }

.pr-3, .px-3 {
  padding-right: 1rem; }

.pt-3, .py-3 {
  padding-top: 1rem; }

.pb-3, .py-3 {
  padding-bottom: 1rem; }

.m-4 {
  margin: 1.5rem !important; }

.ml-4, .mx-4 {
  margin-left: 1.5rem !important; }

.mr-4, .mx-4 {
  margin-right: 1.5rem !important; }

.mt-4, .my-4 {
  margin-top: 1.5rem !important; }

.mb-4, .my-4 {
  margin-bottom: 1.5rem !important; }

.p-4 {
  padding: 1.5rem; }

.pl-4, .px-4 {
  padding-left: 1.5rem; }

.pr-4, .px-4 {
  padding-right: 1.5rem; }

.pt-4, .py-4 {
  padding-top: 1.5rem; }

.pb-4, .py-4 {
  padding-bottom: 1.5rem; }

.m-5 {
  margin: 3rem !important; }

.ml-5, .mx-5 {
  margin-left: 3rem !important; }

.mr-5, .mx-5 {
  margin-right: 3rem !important; }

.mt-5, .my-5 {
  margin-top: 3rem !important; }

.mb-5, .my-5 {
  margin-bottom: 3rem !important; }

.p-5 {
  padding: 3rem; }

.pl-5, .px-5 {
  padding-left: 3rem; }

.pr-5, .px-5 {
  padding-right: 3rem; }

.pt-5, .py-5 {
  padding-top: 3rem; }

.pb-5, .py-5 {
  padding-bottom: 3rem; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }

.custom-control-label {
  margin-bottom: 0;
  margin-left: 5px; }

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  background-color: #007bff; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: 0;
  display: block;
  width: 13px;
  height: 13px;
  pointer-events: none;
  content: "";
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid #007bff; }

.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: 0;
  display: block;
  width: 13px;
  height: 13px;
  content: "";
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }

.custom-checkbox.partial .custom-control-label::after {
  background-color: #007bff;
  width: 9px;
  height: 8px;
  top: 5px;
  left: 2px; }

.custom-tab > .nav-tabs > li.active > a:after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  background-color: #007bff;
  width: 100%;
  height: 5px;
  border-radius: 4px 4px 0 0; }

.custom-tab > .nav-tabs > li.active > a,
.custom-tab > .nav-tabs > li.active > a:hover,
.custom-tab > .nav-tabs > li.active > a:focus {
  border: 0; }

.custom-tab > .nav-tabs > li > a {
  position: relative; }

.w-100 {
  width: 100% !important; }

.h-100 {
  height: 100% !important; }

.w-95 {
  width: 95% !important; }

.h-95 {
  height: 95% !important; }

.w-90 {
  width: 90% !important; }

.h-90 {
  height: 90% !important; }

.w-85 {
  width: 85% !important; }

.h-85 {
  height: 85% !important; }

.w-80 {
  width: 80% !important; }

.h-80 {
  height: 80% !important; }

.w-75 {
  width: 75% !important; }

.h-75 {
  height: 75% !important; }

.w-50 {
  width: 50% !important; }

.h-50 {
  height: 50% !important; }

.d-flex {
  display: flex; }

.d-inline-flex {
  display: inline-flex; }

.flex-row {
  flex-direction: row; }

.flex-column {
  flex-direction: column; }

.flex-row-reverse {
  flex-direction: row-reverse; }

.flex-column-reverse {
  flex-direction: column-reverse; }

.justify-content-start {
  justify-content: flex-start; }

.justify-content-end {
  justify-content: flex-end; }

.justify-content-center {
  justify-content: center; }

.justify-content-space-around {
  justify-content: space-around; }

.justify-content-space-between {
  justify-content: space-between; }

.justify-content-space-evenly {
  justify-content: space-evenly; }

.justify-content-baseline {
  justify-content: baseline; }

.align-items-center {
  align-items: center; }

.align-items-flex-end {
  align-items: flex-end; }

.align-items-flex-start {
  align-items: flex-start; }

.align-items-flex-stretch {
  align-items: stretch; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

/*
  auto balance dialog
  app/route/auto-balance-dialog/auto-balance-dialog.html
*/
.list-dnd {
  margin: 0;
  background-color: #F6F6F6;
  position: relative; }
  .list-dnd > div {
    padding: 16px; }
  .list-dnd > .separator {
    padding: 0;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 100%; }
  .list-dnd .box {
    height: 2.5rem;
    width: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.25rem;
    position: relative;
    top: 20px; }
  .list-dnd span.line {
    display: block;
    width: 2px;
    height: 100%;
    position: absolute;
    background-color: #66AFE9;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1; }
  .list-dnd button.btn-primary[disabled] {
    opacity: 1;
    background-color: #7aa8d0;
    border-color: #7aa8d0; }
  .list-dnd ul {
    padding: 0;
    list-style: none;
    min-height: 60px; }
  .list-dnd li {
    background-color: white;
    padding: .5rem 1rem;
    margin-bottom: 0.25rem;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    border-radius: 0.25rem; }
  .list-dnd .text {
    flex: auto;
    padding: 0 1rem; }
  .list-dnd .right-side li {
    flex-direction: row-reverse;
    text-align: right; }

.loading {
  width: 15px;
  height: 15px;
  position: relative;
  display: inline-block; }

.loading:before,
.loading:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: 2px solid transparent;
  border-top-color: white; }

.loading:before {
  z-index: 100;
  animation: spin 1s infinite; }

.loading:after {
  border: 2px solid transparent; }

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg); } }

.dropdown-menu {
  z-index: 1050; }

.overflow-typeahead [uib-typeahead-popup].dropdown-menu {
  max-height: 170px;
  max-width: 300px;
  font-size: 12.8px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  overflow-y: auto; }

body {
  background: #fafafa;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: #333; }

/* ==========================================================================
Hide ng-cloak on page load, https://docs.angularjs.org/api/ng/directive/ngCloak
========================================================================== */
[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
  display: none !important; }

/* ==========================================================================
Development Ribbon
========================================================================== */
.ribbon {
  background-color: #a00;
  box-shadow: 0 0 10px #888;
  left: -4.5em;
  -moz-box-shadow: 0 0 10px #888;
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  overflow: hidden;
  position: absolute;
  top: 5px;
  transform: rotate(0deg);
  -webkit-box-shadow: 0 0 10px #888;
  -webkit-transform: rotate(-45deg);
  white-space: nowrap;
  width: 15em;
  z-index: 9999;
  pointer-events: none; }
  .ribbon a {
    border: 1px solid #faa;
    color: #fff;
    display: block;
    font: bold 81.25% 'Helvetica Neue', Helvetica, Arial, sans-serif;
    margin: 1px 0;
    padding: 10px 50px;
    text-align: center;
    text-decoration: none;
    text-shadow: 0 0 5px #444;
    pointer-events: none; }

/* ==========================================================================
Version number in navbar
========================================================================== */
.navbar-version {
  font-size: 10px;
  color: #ccc; }

/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */
.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0; }

/* ==========================================================================
Logo styles
========================================================================== */
.navbar-brand.logo {
  padding: 5px 15px; }
  .navbar-brand.logo .logo-img {
    height: 40px;
    display: inline-block; }

/* ==========================================================================
Main page styles
========================================================================== */
.hero-unit {
  margin: 50px auto 0 auto;
  width: 300px;
  font-size: 18px;
  font-weight: 200;
  line-height: 30px;
  background-color: #eee;
  border-radius: 6px;
  padding: 60px; }
  .hero-unit h1 {
    font-size: 60px;
    line-height: 1;
    letter-spacing: -1px; }

.hipster {
  display: inline-block;
  width: 347px;
  height: 497px;
  background: url("../images/hipster.png") no-repeat center top;
  background-size: contain; }

/* wait autoprefixer update to allow simple generation of high pixel density media query */
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .hipster {
    background: url("../images/hipster2x.png") no-repeat center top;
    background-size: contain; } }

/* ==========================================================================
Generic styles
========================================================================== */
.error {
  color: white;
  background-color: red; }

.pad {
  padding: 10px; }

.break {
  white-space: normal;
  word-break: break-all; }

.voffset {
  margin-top: 2px; }

.voffset1 {
  margin-top: 5px; }

.voffset2 {
  margin-top: 10px; }

.voffset3 {
  margin-top: 15px; }

.voffset4 {
  margin-top: 30px; }

.voffset5 {
  margin-top: 40px; }

.voffset6 {
  margin-top: 60px; }

.voffset7 {
  margin-top: 80px; }

.voffset8 {
  margin-top: 100px; }

.voffset9 {
  margin-top: 150px; }

.readonly {
  background-color: #eee;
  opacity: 1; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-italic {
  font-style: italic !important; }

.btn-outline-primary {
  color: #337ab7;
  background-color: transparent;
  background-image: none;
  border-color: #337ab7; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #337ab7;
    border-color: #337ab7; }

.vertical-middle td {
  vertical-align: middle !important; }

.pointer {
  cursor: pointer; }

button[disabled].close {
  cursor: not-allowed; }

@media (min-width: 992px) {
  .modal-xl {
    width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    width: 1140px; } }

/* ==========================================================================
make sure browsers use the pointer cursor for anchors, even with no href
========================================================================== */
a:hover,
.hand,
[jh-sort-by] {
  cursor: pointer; }

/* ==========================================================================
Metrics and Health styles
========================================================================== */
#threadDump .popover,
#healthCheck .popover {
  top: inherit;
  display: block;
  font-size: 10px;
  max-width: 1024px; }

#healthCheck .popover {
  margin-left: -50px; }

.health-details {
  min-width: 400px; }

/* ==========================================================================
start Password strength bar style
========================================================================== */
ul#strength {
  display: inline;
  list-style: none;
  margin: 0;
  margin-left: 15px;
  padding: 0;
  vertical-align: 2px; }

.point {
  background: #ddd;
  border-radius: 2px;
  display: inline-block;
  height: 5px;
  margin-right: 1px;
  width: 20px; }
  .point:last {
    margin: 0 !important; }

/* ==========================================================================
Custom alerts for notification
========================================================================== */
.alerts .alert {
  text-overflow: ellipsis; }
  .alerts .alert pre {
    background: none;
    border: none;
    font: inherit;
    color: inherit;
    padding: 0;
    margin: 0; }
  .alerts .alert .popover pre {
    font-size: 10px; }

.alerts .toast {
  position: fixed;
  width: 100%; }
  .alerts .toast.left {
    left: 5px; }
  .alerts .toast.right {
    right: 5px; }
  .alerts .toast.top {
    top: 55px; }
  .alerts .toast.bottom {
    bottom: 55px; }

@media screen and (min-width: 480px) {
  .alerts .toast {
    width: 50%; } }

/* ==========================================================================
entity tables helpers
========================================================================== */
/* Remove Bootstrap padding from the element
   http://stackoverflow.com/questions/19562903/remove-padding-from-columns-in-bootstrap-3 */
.no-padding-left {
  padding-left: 0 !important; }

.no-padding-right {
  padding-right: 0 !important; }

.no-padding-top {
  padding-top: 0 !important; }

.no-padding-bottom {
  padding-bottom: 0 !important; }

.no-padding {
  padding: 0 !important; }

/* bootstrap 3 input-group 100% width
   http://stackoverflow.com/questions/23436430/bootstrap-3-input-group-100-width */
.width-min {
  width: 1% !important; }

/* Makes toolbar not wrap on smaller screens
   http://www.sketchingwithcss.com/samplechapter/cheatsheet.html#right */
.flex-btn-group-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: flex-end;
  justify-content: flex-end; }

.jh-table > tbody > tr > td {
  /* Align text in td vertically (top aligned by Bootstrap) */
  vertical-align: middle; }

.jh-table > thead > tr > th > .glyphicon-sort,
.jh-table > thead > tr > th > .glyphicon-sort-by-attributes,
.jh-table > thead > tr > th > .glyphicon-sort-by-attributes-alt {
  /* less visible sorting icons */
  opacity: 0.5; }
  .jh-table > thead > tr > th > .glyphicon-sort:hover,
  .jh-table > thead > tr > th > .glyphicon-sort-by-attributes:hover,
  .jh-table > thead > tr > th > .glyphicon-sort-by-attributes-alt:hover {
    /* full visible sorting icons and pointer when mouse is over them */
    opacity: 1;
    cursor: pointer; }

/* ==========================================================================
entity detail page css
========================================================================== */
.dl-horizontal.jh-entity-details > dd {
  margin-bottom: 15px; }

@media screen and (min-width: 768px) {
  .dl-horizontal.jh-entity-details > dt {
    margin-bottom: 15px; }
  .dl-horizontal.jh-entity-details > dd {
    border-bottom: 1px solid #eee;
    padding-left: 180px;
    margin-left: 0; } }

/* ==========================================================================
ui bootstrap tweaks
========================================================================== */
.nav,
.pagination,
.carousel,
.panel-title a {
  cursor: pointer; }

.datetime-picker-dropdown > li.date-picker-menu div > table .btn-default,
.uib-datepicker-popup > li > div.uib-datepicker > table .btn-default {
  border: 0; }

.datetime-picker-dropdown > li.date-picker-menu div > table:focus,
.uib-datepicker-popup > li > div.uib-datepicker > table:focus {
  outline: none; }

.btm-margin-0 {
  margin-bottom: 0px; }

.dynamic-size {
  height: calc(100vh - 52px); }

.btn-file {
  position: relative;
  overflow: hidden; }

.btn-file input[type='file'] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 999px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  background: red;
  cursor: inherit;
  display: block; }

input[readonly] {
  background-color: white !important;
  cursor: text !important; }

/* jhipster-needle-scss-add-main JHipster will add new css style */
li.tabs-import-export {
  width: 50%;
  margin-top: 0.5em; }

.box-import-export {
  padding: 2em;
  border: 1px solid #ddd;
  border-top: 0;
  border-radius: 0 0 4px 4px; }

.import-export-file {
  margin: 1em 0 1em 0; }

.btn-choose-file-custom {
  width: 97px; }

table.sindu-table {
  table-layout: fixed;
  width: 100%; }

.sindu-table {
  background: transparent;
  color: black;
  overflow: hidden; }

.sindu-table th {
  background: #e0e0e0;
  padding: 8px;
  color: black; }

.sindu-table td {
  padding: 8px; }

.sindu-table tr#bodih:nth-child(even) {
  background: #e0e0e0; }

.routings-controller {
  height: calc(100vh - 54px);
  padding: 0px;
  overflow-y: auto;
  overflow-x: hidden; }

.btnSqr {
  width: 34px;
  height: 34px; }

.bring-controller-front {
  z-index: 9999;
  background: white;
  position: absolute;
  bottom: 0; }

.table-routing-controller th {
  padding-right: 1.5em; }

.icon-route-waypoint {
  border: 0.5px solid white;
  background-color: #0974D5;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center; }

.waypoint-empty {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.map-marker {
  text-align: center;
  position: absolute;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center;
  background: greenyellow;
  color: whitesmoke;
  border-radius: 50%;
  font-size: 15px;
  box-shadow: 0px 0px 0px 2px black; }

div.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1024; }

#table-scroll {
  overflow-y: auto;
  width: 98.4%; }

.tooltip {
  position: fixed; }

.table-seq {
  width: 50px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.table-name {
  width: 150px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.table-other {
  width: 100px; }

.has-error {
  color: red;
  font-weight: bold; }

.routing-header {
  /* margin: -14px -20px 0; */
  height: 54px;
  padding: 0 20px;
  line-height: 40px;
  color: #fff;
  background-color: #0074d9; }

.button-automation {
  padding-right: 0;
  bottom: 0; }

.option-routings {
  padding: 0 0 0 13px;
  margin-top: 9px;
  z-index: 2; }

.alert-routing {
  margin-bottom: -27px;
  font-size: 80%; }

.info-routing {
  padding: 0.5em; }

@media (max-width: 1200px) {
  .button-automation {
    bottom: 37px; } }

@media (max-width: 800px) {
  .button-automation-text {
    display: none; } }

@media (max-height: 500px) {
  table.sindu-table.sindu_origin_table > thead > tr > th {
    padding: 7px; }
  table.sindu-table.sindu_origin_table > tbody > tr > td {
    padding: 7px; }
  #table-scroll {
    max-height: 42vh; }
  .alert-routing > .alert {
    padding: 7px; }
  .info-routing {
    bottom: 7px; }
  .option-routings {
    margin-top: 5px; }
  .table-seq-routing {
    bottom: 20px; }
  .routing-estimated-time {
    bottom: 12px; }
  .routing-travel-time {
    bottom: 12px; } }

@media (max-width: 1025px) {
  table.sindu-table.sindu_origin_table > thead > tr > th {
    padding: 7px; }
  table.sindu-table.sindu_origin_table > tbody > tr > td {
    padding: 7px; }
  .table-seq-routing {
    bottom: 27px; }
  .routing-estimated-time {
    bottom: 12px; }
  .routing-travel-time {
    bottom: 12px; }
  .table-seq-routing.sindu_dragging
> ul.sindu_dragger.sindu_row
> li.sindu_static
> table.sindu-table
> thead
> tr
> th {
    padding: 7px; }
  .table-seq-routing.sindu_dragging
> ul.sindu_dragger.sindu_row
> table.sindu-table.sindu_origin_table
> tbody
> tr
> td {
    padding: 7px; }
  .small {
    font-size: 70%; }
  #table-scroll {
    max-height: 35vh; }
  .backToDepotStyle {
    bottom: 27px; }
  .alert-routing {
    margin-top: -12px; } }

@media (max-width: 1197px) {
  .th-routing-controller {
    width: 85px; } }

.legend {
  position: absolute;
  width: 45vw;
  min-height: 400px;
  right: 9px;
  z-index: 2000;
  background: white;
  color: black;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 15px;
  background-clip: padding-box; }

#scrollTab {
  overflow-y: auto;
  max-height: 50vh; }

#closeLegend {
  cursor: pointer; }

#closeLegend:hover {
  color: #d9534f; }

#closeLegend:focus {
  outline: none;
  border: 0; }

.hoverRow:hover {
  background-color: rgba(0, 0, 0, 0.1) !important;
  cursor: pointer; }

.hoverRow:focus {
  outline: none;
  border: 0; }

.row-sm {
  width: 50px; }

.uib-time input {
  height: 22px; }

.text-label {
  color: black;
  font-weight: italic;
  background: transparent;
  border: 0;
  box-shadow: none;
  font-size: 10px;
  margin-top: 20px; }

.leaflet-control-layers-toggle {
  background-image: url(../images/layers.png);
  width: 36px;
  height: 36px; }

.leaflet-retina .leaflet-control-layers-toggle {
  background-image: url(../images/layers-2x.png);
  background-size: 26px 26px; }

/* ==========================================================================
Override leaflet measure icon
========================================================================== */
.leaflet-control-measure .leaflet-control-measure-toggle, .leaflet-control-measure .leaflet-control-measure-toggle:hover {
  background-image: url(../images/leaflet-measure/rulers.png); }

.leaflet-retina .leaflet-control-measure .leaflet-control-measure-toggle, .leaflet-retina .leaflet-control-measure .leaflet-control-measure-toggle:hover {
  background-image: url(../images/leaflet-measure/rulers_@2X.png); }

.leaflet-control-measure a.start {
  background-image: url(../images/leaflet-measure/start.png); }

.leaflet-retina .leaflet-control-measure a.start {
  background-image: url(../images/leaflet-measure/start_@2X.png); }

.leaflet-control-measure a.cancel {
  background-image: url(../images/leaflet-measure/cancel.png); }

.leaflet-retina .leaflet-control-measure a.cancel {
  background-image: url(../images/leaflet-measure/cancel_@2X.png); }

.leaflet-control-measure a.finish {
  background-image: url(../images/leaflet-measure/check.png); }

.leaflet-retina .leaflet-control-measure a.finish {
  background-image: url(../images/leaflet-measure/check_@2X.png); }

.leaflet-measure-resultpopup a.zoomto {
  background-image: url(../images/leaflet-measure/focus.png); }

.leaflet-retina .leaflet-measure-resultpopup a.zoomto {
  background-image: url(../images/leaflet-measure/focus_@2X.png); }

.leaflet-measure-resultpopup a.deletemarkup {
  background-image: url(../images/leaflet-measure/trash.png); }

.leaflet-retina .leaflet-measure-resultpopup a.deletemarkup {
  background-image: url(../images/leaflet-measure/trash_@2X.png); }

.sidebar-pane {
  padding: 10px 6px; }

.timeUnset {
  color: red; }

.backToDepotStyle {
  text-align: center;
  padding: 3px;
  background-color: #0074d9;
  color: white;
  width: 98.4%; }

.hoverRowRouting:hover {
  background-color: rgba(255, 255, 0, 0.5) !important;
  cursor: pointer; }

.hoverRowRouting:focus {
  outline: none;
  border: 0; }

.not-active {
  pointer-events: none;
  cursor: default;
  text-decoration: none;
  color: black; }

.progress-bar-left {
  width: 30px;
  height: 10px;
  display: block;
  background-color: #c4c4c4;
  display: inline-flex;
  margin-left: 1px;
  justify-content: flex-end; }

.progress-bar-right {
  width: 30px;
  height: 10px;
  display: block;
  background-color: #c4c4c4;
  display: inline-flex;
  justify-content: flex-start; }

@media (max-height: 599px) {
  .dynamic-size {
    height: 100vh; } }

.dt-align-left dt {
  text-align: left;
  line-height: 24px; }

.centered {
  margin: 0 auto;
  float: none; }

.dropdown-menu.model-top {
  z-index: 1039; }

tr.ui-sortable-selected {
  background-color: rgba(255, 255, 0, 0.7) !important; }

tr.ui-sortable-selected > td {
  padding: 8px;
  line-height: 1.42857;
  vertical-align: top; }

.ui-sortable-placeholder {
  background-color: #3379b7 !important; }

div.ui-sortable-selected > div {
  color: #4d4d4d;
  background-color: lightblue !important; }

.control-selected-info-pill {
  display: table;
  padding: 1px 5px;
  background-color: whitesmoke;
  border-radius: 5px;
  margin-bottom: 2px;
  border: 1px solid grey;
  width: fit-content; }

.dot {
  height: 12px;
  width: 12px;
  border: 1px black solid;
  border-radius: 50%;
  display: inline-block;
  margin: 1px 1px; }

.range-slider__range {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  border-radius: 5px;
  background: #d7dcdf;
  outline: none;
  padding: 0;
  margin: 0; }

.range-slider__list {
  display: flex;
  justify-content: space-between;
  height: auto;
  overflow: hidden;
  margin-top: 5px; }
  .range-slider__list .range-slider__opt:before {
    content: '';
    display: block;
    width: 0;
    height: auto;
    padding-left: 3px;
    text-indent: 0; }

.bg-danger {
  background-color: #f2dede !important; }

/* ==========================================================================
leaflet side bar override
========================================================================== */
@media (min-width: 768px) {
  .sidebar {
    top: 10px;
    bottom: 10px;
    transition: width 500ms; } }

@media (min-width: 768px) and (max-width: 991px) {
  .sidebar {
    width: 305px; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .sidebar {
    width: 570px; } }

@media (min-width: 1200px) {
  .sidebar {
    width: 570px; } }

@media (min-width: 768px) {
  .sidebar-left ~ .sidebar-map .leaflet-left {
    transition: left 500ms; } }

@media (min-width: 768px) and (max-width: 991px) {
  .sidebar-left ~ .sidebar-map .leaflet-left {
    left: 315px; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .sidebar-left ~ .sidebar-map .leaflet-left {
    left: 400px; } }

@media (min-width: 1200px) {
  .sidebar-left ~ .sidebar-map .leaflet-left {
    left: 575px; } }

@media (min-width: 768px) {
  .sidebar-left.collapsed ~ .sidebar-map .leaflet-left {
    left: 50px; } }

.leaflet-touch .leaflet-control-measure .leaflet-control-measure-toggle, .leaflet-touch .leaflet-control-measure .leaflet-control-measure-toggle:hover {
  width: 32px;
  height: 32px; }

/* ==========================================================================
Moving stop
========================================================================== */
.moving-stop-box {
  border: 2px solid #C1C1C1;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px 10px 0px 0px;
  background: #ffffff;
  padding-top: 13px; }

.shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px; }

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0); }
  20%, 80% {
    transform: translate3d(2px, 0, 0); }
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0); }
  40%, 60% {
    transform: translate3d(4px, 0, 0); } }

/* ==========================================================================
sync feature styles
========================================================================== */
.bdf-sync-wrapper {
  margin: 0px 75px 19px 0px;
  overflow: hidden; }

.sync-dialog {
  background-color: white;
  width: 290px;
  max-height: 0px;
  box-shadow: none;
  border-radius: 0px;
  transition: 0.3s all;
  overflow: hidden; }

.sync-dialog.morph {
  max-height: 322px;
  height: auto;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 10px 10px; }

.sync-dialog.morph.expand {
  max-height: 420px; }

.sync-btn-group {
  width: 290px;
  height: 44px;
  background: #0074D9;
  border-radius: 32px;
  padding: 9.5px 0px;
  font-size: 14px;
  color: #FAFAFA;
  cursor: pointer;
  font-weight: 700;
  transition: 1.2s all; }

.sync-btn-group.morph {
  border-radius: 10px 10px 0px 0px;
  cursor: default; }

.side-btn {
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 46px;
  transition: 0.4s all; }
  .side-btn span {
    display: none; }

.side-btn.show-btn {
  width: 185px; }
  .side-btn.show-btn span {
    margin-left: 10px;
    display: inline;
    white-space: nowrap;
    overflow: hidden; }

.mid-btn {
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 46px;
  border-left: 1px solid #fafafa;
  border-right: 1px solid #fafafa;
  transition: 0.4s all; }
  .mid-btn span {
    display: none; }

.mid-btn.show-btn {
  width: 151px; }
  .mid-btn.show-btn span {
    margin-left: 10px;
    display: inline;
    white-space: nowrap;
    overflow: hidden; }

.no-changes {
  color: #505050;
  font-size: 12px;
  font-weight: bold; }

.conflict-title {
  font-size: 12px;
  color: #747474; }

.conflict-info {
  padding: 12px 15px; }

.conflict-list {
  margin-bottom: 10px;
  font-size: 12px;
  color: #505050; }

.conflict-stand-out {
  color: #505050;
  font-size: 20px;
  font-weight: bold;
  margin-right: 3px; }

/* ==========================================================================
Register
========================================================================== */
.register-page .warp-title {
  margin-bottom: 30px; }

.register-page h1 {
  color: #14486D;
  font-family: Montserrat;
  font-size: 34px;
  line-height: 41px;
  text-align: center;
  margin-bottom: 5px;
  margin-top: 25px; }

.register-page h2 {
  font-family: Montserrat;
  font-size: 23px;
  line-height: 28px;
  text-align: center;
  color: #14486D;
  margin-top: 0; }

.register-page ul#strengthBar {
  padding-left: 0; }

.register-page .footer-text {
  font-family: Roboto;
  font-size: 14px;
  line-height: 16px;
  color: #14486D; }
  .register-page .footer-text a {
    font-weight: bold; }

.warp-form-register {
  padding: 50px 45px;
  background-color: #F0F0F0; }

.warp-testimonial .text {
  background-color: #007DAC;
  min-height: 353px;
  padding: 40px;
  padding-top: 10px;
  padding-bottom: 90px;
  font-family: Montserrat;
  font-size: 16px;
  line-height: 147.69%;
  color: #FAFAFA; }
  .warp-testimonial .text h3 {
    font-size: 31px;
    line-height: 146.19%;
    text-align: center;
    letter-spacing: 0.07em;
    color: #FAFAFA;
    margin-bottom: 25px; }
  .warp-testimonial .text p {
    max-width: 90%;
    margin: auto; }
  .warp-testimonial .text span {
    position: absolute; }

.warp-testimonial .user-testimonial {
  text-align: center; }
  .warp-testimonial .user-testimonial img {
    border-radius: 50%;
    padding: 10px;
    background-color: white;
    margin-top: -60px; }
  .warp-testimonial .user-testimonial h4 {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #14486D;
    margin-bottom: 5px; }
  .warp-testimonial .user-testimonial h5 {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #14486D;
    margin-top: 0px; }

/* ==========================================================================
stop tooltips
========================================================================== */
.stop-tooltips {
  font-size: 12px;
  padding: 0px;
  width: 300px; }

.stop-tooltips .stop-header {
  color: white;
  font-weight: bold;
  background-color: #0974D5; }

/* ==========================================================================
toggle
========================================================================== */
.switch {
  position: relative;
  display: inline-block;
  width: 33px;
  height: 14px; }

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0; }

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s; }

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: -5px;
  bottom: -2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25); }

input:checked + .slider {
  background-color: #2196F3; }

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3; }

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
  background-color: #337AB7; }

/* Rounded sliders */
.slider.round {
  border-radius: 34px; }

.slider.round:before {
  border-radius: 50%; }

/* ==========================================================================
setting poi
========================================================================== */
.setting-poi ul ul li {
  background-color: white;
  background-color: white;
  padding: 5px 0;
  padding-left: 40px; }

.setting-poi ul ul li:nth-child(odd) {
  background-color: #F0F0F0; }

/* ==========================================================================
depot gravity
========================================================================== */
@media only screen and (max-width: 1366px) {
  .gravity-legend-list {
    max-height: 154px;
    overflow-y: auto; } }

/* ==========================================================================
bps setting
========================================================================== */
.bps-bullet-list {
  margin-right: 5px;
  font-size: 11px;
  font-weight: bold;
  width: 20px;
  height: 16px;
  border-radius: 20px;
  background-color: #75CAEB;
  display: inline-flex;
  justify-content: center;
  color: white;
  align-items: center; }

.bps-label {
  display: flex;
  align-items: center;
  margin-bottom: 5px; }

.bps-block-button {
  color: #0374DA;
  font-weight: bold;
  background-color: #DAF3FF;
  display: flex;
  justify-content: space-between;
  padding: 14px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 100%;
  font-size: 13px; }

.bps-block-button:hover {
  color: #0374DA; }

.bps-list {
  font-size: 12px;
  color: #505050;
  padding: 14px;
  display: flex;
  justify-content: space-between; }

.bps-list:nth-child(odd) {
  background: #F0F0F0; }

.bps-list:nth-child(even) {
  background: #FBFBFB; }

.bps-list-title {
  padding: 14px;
  background-color: #ffffff;
  border-bottom: 2px solid #D1D1D1;
  margin-bottom: 2px; }

.bps-list-container {
  max-height: 350px;
  overflow-y: auto;
  overflow-x: hidden; }

@media screen and (min-width: 1025px) and (max-width: 1366px) {
  .bps-list-container {
    max-height: 350px; } }

@media screen and (min-width: 1367px) and (max-width: 1600px) {
  .bps-list-container {
    max-height: 450px; } }

/* ==========================================================================
project info card
========================================================================== */
.project-card {
  width: 410px;
  font-size: 13px;
  font-weight: 700; }
  .project-card .title {
    color: #FAFAFA;
    padding: 14px 15px;
    background-color: #00ADEE;
    border-radius: 10px 10px 0px 0px; }
  .project-card .full-radius {
    border-radius: 10px; }
  .project-card button {
    width: 100%; }
  .project-card .btn.btn-optimize {
    background-color: white;
    color: #009BD5; }
  .project-card .route-violation {
    display: flex;
    padding: 14px 15px;
    max-height: 100px;
    background-color: #FEFFFF;
    overflow-y: auto;
    border-radius: 0px 0px 10px 10px; }

/* ==========================================================================
route detail list info card
========================================================================== */
.route-card {
  height: 114px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  flex: 0 1 24%;
  padding: 20px 15px;
  color: #FAFAFA;
  position: relative; }

.route-card .summary-icon {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.1; }

.route-card.traveling-time {
  background: linear-gradient(180deg, #0074D9 0%, #005AA8 100%); }

.route-card.distance {
  background: linear-gradient(180deg, #00CAF0 0%, #00AFD0 100%); }

.route-card.work-time {
  background: linear-gradient(180deg, #FFAA00 0%, #DD9300 100%); }

.route-card.cost-per-month {
  background: linear-gradient(180deg, #229520 0%, #137C11 100%); }

.custom-window-dialog .modal-dialog {
  width: 90vw; }

.btn.btn-project {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #FAFAFA;
  background: #009BD5;
  border-radius: 5px; }

/* ==========================================================================
jitu poi
========================================================================== */
.jitu-data-wrapper {
  font-size: 13px;
  padding: 15px 0px;
  overflow-y: auto;
  min-height: 50px;
  max-height: 290px; }

.jitu-data-header {
  padding: 14px 34px;
  display: flex;
  justify-content: space-between;
  background: #DAF3FF;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-weight: bold;
  color: #0374DA;
  font-size: 13px; }

.jitu-list-wrapper {
  padding: 5px 34px 5px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e1e1e1;
  color: #505050;
  font-size: 12px; }

.jitu-sub-list {
  display: flex;
  justify-content: space-between;
  padding: 5px 35px;
  font-weight: bold;
  font-size: 12px; }

.jitu-sub-list:nth-child(even) {
  background-color: #F0F0F0; }

.week-day .jitu-data-wrapper {
  overflow-y: auto;
  min-height: 50px;
  max-height: 250px; }

/* ==========================================================================
sidebar route planning
========================================================================== */
.table-sidebar {
  margin: 0;
  margin-top: 10px; }

.table-sidebar th {
  font-weight: normal;
  font-size: 12px;
  border: 0 !important;
  line-height: 13px !important;
  vertical-align: middle !important;
  padding: 5px 8px !important; }

.table-sidebar td {
  font-weight: bold;
  border: 0 !important; }

.table-sidebar td, .table-sidebar th {
  max-width: 50px;
  text-align: center; }

.btn-transparent-blue {
  background-color: #98C5EB;
  color: white; }

.btn-transparent-blue:hover {
  color: white; }

.btn-transparent-blue:focus {
  color: white;
  outline: none; }

.fa.angel-blue {
  flex: 0 0 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0074D9;
  font-size: 18px; }

.list-sidebar {
  padding: 10px 12px;
  border-top: 1px solid #0974D5;
  position: sticky;
  top: 178px;
  background-color: white;
  border-left: 5px solid transparent; }

.statistic-area {
  margin: 0 -12px;
  margin-bottom: -10px;
  background-color: white;
  padding: 0 12px; }

.list-sidebar.active {
  border-left-color: #0974D5; }

.list-sidebar:last-child {
  border-bottom: 1px solid #0974D5; }

.sidebar-left .sidebar-close {
  right: 14px; }

.custom-tab.week-day > ul {
  position: sticky;
  top: 183px; }

.custom-tab.week-day .list-sidebar {
  top: 220px; }

.custom-tooltip-notes {
  position: absolute; }

.balance-indicator {
  border-radius: 4px;
  background: #F2F2F2;
  padding: 8px;
  cursor: pointer;
  height: 48px; }

.dropdown-submenu {
  position: relative;
  padding: 3px 20px; }

.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
  -webkit-border-radius: 0 6px 6px 6px;
  -moz-border-radius: 0 6px 6px;
  border-radius: 0 6px 6px 6px;
  padding: 5px 0px;
  cursor: pointer; }

.dropdown-submenu:hover > .dropdown-menu {
  display: block; }

.dropdown-submenu > a:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: #ccc;
  margin-top: 5px;
  margin-right: -10px; }

.dropdown-submenu:hover > a:after {
  border-left-color: #fff; }

.dropdown-submenu.pull-left {
  float: none; }

.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
  -webkit-border-radius: 6px 0 6px 6px;
  -moz-border-radius: 6px 0 6px 6px;
  border-radius: 6px 0 6px 6px; }

/* ==========================================================================
route-plan/one-click-optimize
========================================================================== */
.optimize {
  display: flex; }
  .optimize .grafis {
    flex: 0 0 335px;
    max-width: 335px; }
  .optimize .content {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    padding: 16px;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between; }
  .optimize .body {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .optimize .uib-separator {
    padding: 0 5px;
    color: black; }
  .optimize h1 {
    font-size: 20px;
    margin-top: 0; }
  .optimize .container-territory {
    overflow-y: auto;
    max-height: 400px; }
    .optimize .container-territory thead {
      position: sticky;
      top: 0; }
    .optimize .container-territory th {
      background-color: white; }
  .optimize .container-stepper {
    display: flex; }
  .optimize .container-stepper span {
    width: 86.5px;
    height: 10px;
    border-radius: 10px;
    background-color: #E0E0E0;
    margin-right: 10px; }
  .optimize .container-stepper span.done {
    background-color: #66AFE9; }
  .optimize .container-near-day {
    font-size: 12px; }
    .optimize .container-near-day .list-container {
      background-color: #e1e1e1;
      padding: 20px 16px;
      width: 100%; }
    .optimize .container-near-day .title {
      font-weight: bold; }
    .optimize .container-near-day .list-title {
      color: #828282;
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 10px; }
    .optimize .container-near-day .list {
      margin-bottom: 10px;
      padding: 8px 16px;
      border-radius: 4px;
      border: 1px solid var(--Gray-6, #F2F2F2);
      background: #FFF;
      box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .optimize .container-near-day .list .day-number {
        text-align: center;
        padding: 4px 8px;
        border-radius: 4px;
        background: #56CCF2;
        color: white;
        font-weight: bold;
        width: 50px; }
      .optimize .container-near-day .list .day-name {
        width: 200px;
        font-weight: bold;
        text-align: center; }
      .optimize .container-near-day .list .day-anchor {
        width: 50px;
        display: flex;
        justify-content: end; }
    .optimize .container-near-day .list.ui-sortable-selected .day-number {
      background: #56CCF2 !important;
      color: white !important; }
    .optimize .container-near-day .list.ui-sortable-selected .day-name {
      background: none !important;
      color: black !important; }

#outlet-legend .uib-time input {
  height: 30px;
  padding: 0;
  width: 30px; }

#outlet-legend td.uib-separator {
  padding: 0 3px; }

#speed-config {
  font-size: 11px; }

#speed-config datalist {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 2px;
  padding: 0 5px; }

#speed-config input[type="range"] {
  width: 100%; }

#import-stop .pagination {
  margin-top: 15px;
  margin-bottom: 0px; }

.nowrap {
  white-space: nowrap; }

.no-btn-decorator:hover {
  text-decoration: none; }

.no-btn-decorator:focus {
  outline: none;
  text-decoration: none; }

.no-btn-decorator:active:focus {
  outline: none;
  text-decoration: none; }
